import BigNumber from 'bignumber.js'

import { LimitOrderData, LimitOrderEvent } from '../../model/limitOrderModel'

const seriesNoncePredicateSelector = '32565d61'
const noncePredicateSelector = 'cf6fc6e3'
const timestampPredicateSelector = '63592c2b'
enum LimitOrderCompleteStatus {
  FILLED = 'Filled',
  CANCELED = 'Cancelled',
  EXPIRED = 'Expired',
  ACTIVE = 'Active',
}

export function getOrderStatus(
  limitOrder: LimitOrderData,
  events: LimitOrderEvent[]
): LimitOrderCompleteStatus {
  const fullFillEvent = events.find((event) => {
    return event.action === 'fill' && event.remainingMakerAmount === '0'
  })

  const cancelEvent = events.find((event) => {
    return event.action === 'cancel'
  })

  if (fullFillEvent) {
    return LimitOrderCompleteStatus.FILLED
  }

  if (cancelEvent) {
    return LimitOrderCompleteStatus.CANCELED
  }

  const expireTimestamp = getExpireTimestampFromOrder(limitOrder.data.predicate)

  if (expireTimestamp !== null && Date.now() / 1000 > expireTimestamp) {
    return LimitOrderCompleteStatus.EXPIRED
  }

  if (
    expireTimestamp !== null &&
    Date.now() / 1000 < expireTimestamp &&
    new BigNumber(limitOrder.remainingMakerAmount).gt(0)
  ) {
    return LimitOrderCompleteStatus.ACTIVE
  }

  return LimitOrderCompleteStatus.EXPIRED
}

export function getNonceFromOrder(predicate: string): number | null {
  const selectorIndex = predicate.indexOf(noncePredicateSelector)

  if (selectorIndex < 0) {
    return null
  }

  const noncePredicateStart = selectorIndex + noncePredicateSelector.length + 64
  const nonceHex = predicate.slice(noncePredicateStart, noncePredicateStart + 64)

  return +('0x' + nonceHex)
}

export function getSeriesNonceFromOrder(
  predicate: string,
  loSeriesContractAddress: string
): number | null {
  const selectorIndex = predicate.indexOf(seriesNoncePredicateSelector)
  const eqPredicateStart = selectorIndex + seriesNoncePredicateSelector.length
  const loSeriesContractAddressFromPredicate = predicate.slice(
    eqPredicateStart + 64,
    eqPredicateStart + 64 + 64
  )
  const containsLoSeriesContractAddress = loSeriesContractAddressFromPredicate.endsWith(
    loSeriesContractAddress.slice(2)
  )

  if (selectorIndex < 0 || !containsLoSeriesContractAddress) {
    return null
  }

  const nonceHex = predicate.slice(eqPredicateStart, eqPredicateStart + 64)

  return +('0x' + nonceHex)
}

export function getExpireTimestampFromOrder(predicate: string): number | null {
  const selectorIndex = predicate.indexOf(timestampPredicateSelector)

  if (selectorIndex < 0) {
    return null
  }

  const timestampPredicateStart = selectorIndex + timestampPredicateSelector.length
  const timestamp = predicate.slice(timestampPredicateStart, timestampPredicateStart + 64)

  return parseInt('0x' + timestamp) * 1000
}

export const getRPCErrorText = (error?: string): string => {
  // returning error format is '[ethjs-query] while formatting outputs from RPC {...JSON...}'
  // so we need to parse it to get human-readable message

  const errorCheck = (error || 'MetaMask RPC error').split(
    '[ethjs-query] while formatting outputs from RPC'
  )

  let errorResult

  if (errorCheck[1]) {
    const errorObj = JSON.parse(errorCheck[1].replace(/\s'(.*)'/, '$1'))
    errorResult = `MetaMask RPC error: ${errorObj.value.data.message}`
  } else {
    errorResult = errorCheck[0]
  }

  return errorResult
}
